<template>
  <div>
    <MbnTableTools
      :search="search"
      :limit="limit"
      @updateSearchQuery="search = $event"
      @updateLimit="updateLimit"
      @resetSearch="resetSearch"
    />

    <CRow>
      <CCol>
        <CCard>
          <CCardHeader class="d-flex align-items-center justify-content-between">
            <span>{{ $t('sidebar.sites') }}</span>
          </CCardHeader>
          <CCardBody>
            <MbnErrorMessage v-if="error" />

            <CDataTable
              v-else
              :fields="fields"
              :items="tableItems"
              striped
              add-table-classes="section-table"
              :no-items-view="{ noItems: $t('ui.no_items_available') }"
              :sorter="{ external: false, resetable: false }"
              :sorter-value="{ column: 'id', asc: false }"
              :loading="isLoading"
              hover
            >
              <template #active="{ item }">
                <td>
                  <CBadge :color="getBadge(item.active)">
                    {{ item.active ? $t('ui.active') : $t('ui.inactive') }}
                  </CBadge>
                </td>
              </template>
              <template #moderated="{ item }">
                <td>
                  <CBadge :color="getBadge(item.moderated)">
                    {{ item.moderated ? $t('ui.active') : $t('ui.inactive') }}
                  </CBadge>
                </td>
              </template>
              <template #show_details="{ item }">
                <td>
                  <div class="d-flex">
                    <CButton :title="$t('ui.approve')" size="sm" class="mr-3" color="info" @click="accept(item)">
                      <CIcon name="cil-thumb-up" />
                    </CButton>
                    <CButton :title="$t('ui.not_approve')" size="sm" class="mr-3" color="danger" @click="decline(item)">
                      <CIcon name="cil-thumb-down" />
                    </CButton>
                  </div>
                </td>
              </template>
            </CDataTable>

            <CPagination
              v-if="isShowedPagination"
              :pages="totalPages"
              :active-page="activePage"
              size="sm"
              @update:activePage="onChangePage"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { GET_SITES_LIST, EDIT_SITE } from '@/store/action-types/admin';
import { limits } from '@/helpers/const';
import { getBadge } from '@/helpers/utils';
import searchByTable from '@/helpers/search-by-table';
import MbnTableTools from '@/components/TableTools';
import MbnErrorMessage from '@/components/ErrorMessage';

export default {
  name: 'AdminSites',
  components: {
    MbnTableTools,
    MbnErrorMessage,
  },
  data() {
    return {
      limit: this.$route.query.limit || limits[0].value,
      search: '',
      filtered: '',
      activePage: Number(this.$route.query.page || '1'),
    };
  },
  computed: {
    ...mapState({
      sitesList: (state) => state.admin.sites.list,
      isLoading: (state) => state.admin.sites.isLoading,
      error: (state) => state.admin.sites.error,
      total: (state) => state.admin.sites.total,
    }),
    tableItems() {
      return this.filtered ? this.filtered : this.sitesList;
    },
    totalPages() {
      return Math.ceil(this.total / this.limit);
    },
    offset() {
      return this.activePage * this.limit - this.limit;
    },
    isShowedPagination() {
      return this.totalPages > 1 && !this.filtered;
    },
    fields() {
      return [
        { key: 'id', label: this.$t('sites.id') },
        { key: 'userId', label: this.$t('sites.user_id') },
        { key: 'url', label: this.$t('sites.url') },
        { key: 'category', label: this.$t('sites.category') },
        { key: 'active', label: this.$t('sites.active') },
        { key: 'moderated', label: this.$t('sites.moderated') },
        { key: 'show_details', label: this.$t('ui.actions'), sorter: false },
      ];
    },
  },
  watch: {
    search(value) {
      this.filterData(value);
    },
    activePage() {
      this.fetchSitesList();
    },
    limit() {
      this.fetchSitesList();
    },
  },
  mounted() {
    this.fetchSitesList();
  },
  methods: {
    ...mapActions('admin', [GET_SITES_LIST, EDIT_SITE]),
    resetSearch() {
      this.search = '';
    },
    updateLimit(value) {
      this.limit = value;
      this.activePage = 1;
      this.$router.push({ query: { limit: this.limit } });
    },
    filterData(query) {
      this.filtered = searchByTable(query, this.sitesList);
    },
    getBadge(status) {
      return getBadge(status);
    },
    onChangePage(page) {
      this.activePage = page;
      this.$router.push({ query: { limit: this.limit, page } });
    },
    fetchSitesList() {
      this.GET_SITES_LIST({
        limit: this.limit,
        offset: this.offset,
      });
    },
    accept(record) {
      this.update(record, { field: 'moderated', value: true });
    },
    decline(record) {
      this.update(record, { field: 'moderated', value: false });
    },
    update(record, prop) {
      const site = { ...record };
      site[prop.field] = prop.value;
      this.EDIT_SITE({ id: site.id, site })
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.updated'),
          });
          this.fetchSitesList();
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.updating_record_error'),
          });
        });
    },
  },
};
</script>
